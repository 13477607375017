<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-single-02 "></i> Database
      </h6>
    </h1>
    
    <base-header class="pb-6">
      <b-row>
        <b-col xl="12" md="12">
          <b-card style="position: relative; overflow: hidden">
            <h3>Cari Berdasarkan</h3>

            <div
              class="d-flex justify-content-start"
              style="position: relative; z-index: 999"
            >
              <!-- status pendaftaran -->
              <!-- <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div> -->

              <!-- Tanggal -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
              >
                <label><small>Tanggal</small></label>
                <base-input name="Tanggal">
                  <flat-picker
                    placeholder="Filter Tanggal"
                    :config="provideSelect.flatPickerConfig"
                    class="form-control datepicker"
                    v-model="userInput.tanggal"
                    style="background: white"
                  />
                </base-input>
              </div>

              <!-- Jenis Industri -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Jenis Industri</small></label>
                <base-input>
                  <el-select
                    filterable
                    placeholder="Jenis Industri"
                    v-model="userInput.companyType"
                  >
                    <el-option value="">Semua Jenis Industri</el-option>
                    <el-option
                      v-for="(option, idx) in provideSelect.companyType"
                      :key="idx"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Provinsi -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Provinsi</small></label>
                <base-input name="Provinsi">
                  <el-select
                    v-model="form.umum.compProvince"
                    @change="getCityDepentOnProvince(form.umum.compProvince)"
                    filterable
                  >
                    <el-option label="Semua Provinsi" :value="null">
                    </el-option>
                    <el-option
                      v-for="option in provideSelect.provinsi"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Kabupaten / Kota</small></label>
                <base-input name="Kabupaten / Kota">
                  <el-select v-model="form.umum.compCity">
                    <el-option
                      v-for="option in provideSelect.kabupatenKota"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Kabupaten kota -->
              <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota">
                    <select
                      class="form-control"
                      v-model="form.umum.compCity"
                    >
                      <option
                        v-for="(option, idx) in provideSelect.kabupatenKota"
                        :key="idx"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </base-input>
                </div> -->

              <!-- Nama Industri -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
              >
                <label><small>Nama Industri</small></label>
                <base-input name="Nama Industri">
                  <el-select
                    v-model="userInput.companyName"
                    @change="getMyCompliencePoint"
                    :disabled="log_as !== 'admin'"
                  >
                    <el-option value="">Semua</el-option>
                    <el-option
                      v-for="option in provideSelect.companyName"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Titik Penaatan -->
              <div style="display: flex; flex-direction: column; width: 180px">
                <label><small>Titik Penaatan</small></label>
                <base-input>
                  <el-select
                    filterable
                    placeholder="Titik Penaatan"
                    v-model="userInput.titikPenaatan"
                  >
                    <el-option value="">Semua</el-option>
                    <el-option
                      v-for="option in listDataCompliencePoints"
                      :key="option.id"
                      :label="option.nama_titik_penaatan"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <base-button size="md" type="primary" @click="getBaseOnStatus">
              Tampilkan
            </base-button>
            <img
              src="img/brand/klhk.png"
              alt=""
              style="
                position: absolute;
                right: 0;
                top: -100px;
                z-index: 50;
                opacity: 0.1;
              "
            />
          </b-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                  <button
                    class="btn text-white btn-sm"
                    style="background-color: #0a7641"
                    @click="downloadExcelNow"
                  >
                    <i
                      class="fa fa-solid fa-file-excel mr-2"
                      style="font-size: 20px"
                    ></i>
                    Unduh Excel
                  </button>
              </b-col>
            </b-row>
            </template>

            <section id="mytable">
                <el-table
                  :data="listData"
                  row-key="created_at"
                  header-row-class-name="thead-light"
                >
                  <el-table-column label="No" type="index" width="100" />

                  <el-table-column min-width="150px" label="Nama Industri">
                    <div slot-scope="action">
                      {{ action.row.comp_name }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="150px" label="ID">
                    <div slot-scope="action">
                      {{ action.row.id }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="200px"
                    label="Jenis Industri"
                  >
                    <div slot-scope="action">
                      {{ action.row.comp_type_name}}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="180px"
                    label="Provinsi"
                  >
                    <div slot-scope="action">
                      {{ action.row.province_name }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="180px"
                    label="Kabupaten / Kota"
                  >
                    <div slot-scope="action">
                      {{ action.row.kabkot_name}}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="170px" label="Alamat">
                    <div slot-scope="action">
                      {{ action.row.comp_address }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="150px" label="Telp Kantor">
                    <div slot-scope="action">
                      {{ action.row.company_phone }}
                    </div>
                  </el-table-column>

                  

                 
                </el-table>

                <div class="py-3">
                  <el-select
                    class="select-primary pagination-select mr-3"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    @change="changeSizePage()"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <small class="card-category">
                    Showing {{ 1 }} to {{ listData.length }} of
                    {{ pagination.total }} entries
                  </small>
                </div>

                <div style="display: flex; justify-content: flex-end">
                  <base-pagination
                    class="pagination-no-border"
                    :current="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </div>
              </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

     <!-- Loading Screen -->
     <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img
                  src="img/brand/klhk.png"
                  alt=""
                  style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  "
                />
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

   

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div>
              <div>
                <div v-if="isLoading" class="text-center" style="margin-top: 200px;"><h1>Sedang Menyiapkan Data ...</h1></div>
                <div v-else style="padding: 10px; overflow-x: scroll" class="customTable">
                  <!-- <table class=" fl-table mt-3"  ref="exportable_table" > -->
               

                  <table class="fl-table mt-3" id="multiLevelTable" >
                    <thead>
                      <tr>
                          <th>NO</th>
                          <th>Nama Industri</th>
                          <th>Jenis Industri</th>
                          <th>Kabupaten / Kota</th>
                          <th>Provinsi</th>
                          <th>Alamat</th>
                          <th>Nomor Telepon</th>
                          <th>Sumber Limbah</th>
                          <th>WTP Tech</th>
                      </tr>
                    </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="exportThisWithParameter('multiLevelTable', 'database.xls')"
              :disabled="isLoading"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from '../../../api/base_url'
import { Select, Option, Table, TableColumn } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DatabaseTable from '../../Tables/AdminTable/Database.vue';
import moment from "moment";

// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";



export default {
components: {
  Modal,
  BaseHeader,
  DataSparingChart,
  flatPicker,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn,
  DatabaseTable
},
mounted() {
    // new
  (this.log_as = localStorage.getItem("as"));
  this.getCompliencePoint();
  this.getCompanyType();
  this.getCompanyName();
  this.getSelectFormValue();
  this.getCompanyProfile();

  this.getData()
},
data() {
  return {
    pdfName: "",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      selectOptionStatus: [
        {
          val: "",
          name: "Semua",
        },
        {
          val: 0,
          name: "Menunggu",
        },
        {
          val: 1,
          name: "Disetujui",
        },
        {
          val: 2,
          name: "Ditolak",
        },
      ],
      userInput: {
        tanggal: "",
        registrationType: "SP",
        titikPenaatan: "",
        companyType: "",
        companyName: "",
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm",
                  },
                },
                {
                  label: "Data 1 Minggu",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm",
                  },
                },
                {
                  label: "Data 1 Bulan",
                  attributes: {
                    class: "btn w-75 btn-dark mb-5 btn-sm",
                  },
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes: {
                    class: "btn w-100 btn-danger btn-sm",
                  },
                },
              ],
              onClick: (index, fp) => {
                fp.clear();

                if (index == 0) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 1) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).add(1, "week").endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 2) {
                  const startDate = moment().startOf("month");
                  const endDate = moment().endOf("month");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else {
                  fp.clear();
                }
              },
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType: [],
        compiencePoint: [],
        companyType: [],
        companyName: [],
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal: false,
      showDownloadModalCSV: false,
      // end of filter
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1,
      },
      log_as: "",
      listData: [],
  };
},
methods: {
  getData() {
      this.isLoading = true;

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`loggers?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}`,
        { headers }
      )
        .then(({ data }) => {
          if (data.data == null) {
            this.listData = [];
          } else {
            console.log(data.data,'--->');
            this.listData = data.data;
            this.pagination.total = data.meta.pagination.total_items;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  getDataDownload() {
      this.isLoading = true;

      let tmpDate = this.userInput.tanggal.split("to");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const queryParams = {
        page: 1,
        page_size: 100,
        status: this.filterStatusPendaftaran,
        start_date: startDate,
        end_date: endDate,
        province_id: this.form.umum.compProvince,
        regency_id: this.form.umum.compCity,
        company_id: this.userInput.companyName,
        company_type_id: this.userInput.companyType,
        sparing_compliance_point_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );

      API.get(`report/data/hourly`, { params, headers })
        .then(({ data }) => {
          if (data.data == null) {
            this.listDataDownload = [];
            this.isLoading = false;
          } else {
            this.listDataDownload = data.data;
            this.pagination.total = data.meta.info.count;
            setTimeout(() => {
              this.isLoading = false;
            }, 200);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    downloadPDFNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }

      this.getDataDownload();
      this.showDownloadModal = true;
    },
    downloadExcelNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }
    
      this.getDataDownload();
      this.showDownloadModalCSV = true;
    },
    getCompanyProfile() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`companies/me`, { headers })
        .then(({ data }) => {
          if (data.data == null) {
          } else {
            this.companyMe = data.data;

            if (this.log_as !== "admin") {
              this.userInput.companyName = data.data.company.id;
              this.pdfName = data.data.company.name;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyType() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("companies/types?page=1&page_size=100", { headers })
        .then(({ data: content }) => {
          this.provideSelect.companyType = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyName() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("companies?page=1&page_size=1000", { headers })
        .then(({ data: content }) => {
          this.provideSelect.companyName = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectFormValue() {
      API.get(`region/provinces?page=1&page_size=100`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCityDepentOnProvince(data) {
      this.provideSelect.kabupatenKota = [];
      API.get(`region/provinces/regencies?province_id=${data}`)
        // API.get(`region/regencies/province/${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`companies/sparings/compliance-points`, { headers })
        .then(({ data: content }) => {
          this.listDataCompliencePoints = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMyCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/sparings/compliance-points?company_id=${this.userInput.companyName}`,
        { headers }
      )
        .then(({ data: content }) => {
          this.listDataCompliencePoints = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
      this.isLoading = true;

      let tmpDate = this.userInput.tanggal.split("to");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const queryParams = {
        page: this.pagination.currentPage,
        page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        start_date: startDate,
        end_date: endDate,
        province_id: this.form.umum.compProvince,
        regency_id: this.form.umum.compCity,
        company_id: this.userInput.companyName,
        company_type_id: this.userInput.companyType,
        sparing_compliance_point_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );

      API.get(`report/data/hourly`, { params, headers })
        .then(({ data }) => {
          if (data.data == null) {
            this.listData = [];
            this.isLoading = false;
          } else {
            this.listData = data.data;
            this.pagination.total = data.meta.info.count;
            setTimeout(() => {
              this.isLoading = false;
            }, 200);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    async print() {
      try {
        // Dapatkan data dari objek
        const data = this.listDataDownload;

        // Buat dokumen jsPDF
        const doc = new jsPDF();

        // Tambahkan header
        const header = [
          "No",
          "Nama Industri",
          "Waktu",
          "PH",
          "COD (MG/L)",
          "TSS (MG/L)",
          "NH3N (MG/L)",
          "DEBIT (MG3/MENIT)",
          "Beban COD",
          "Beban TSS",
          "Beban NH3N",
        ];

        let headerPemenuhan = [
                "No",
                "Pemantauan terus menerus dalam jaringan",
                "Jumlah Data yang Memenuhi Baku Mutu Air Limbah",
                "Presentase Pemenuhan Baku Mutu Air Limbah"
        ];
        let compName = ""
        let compAddress = ""
        let compLongitude = ""
        let compLatitude = ""
        let compTlp = ""
        let userEmail = ""
        let compType = ""
        let compSumberLimbah = ""
        let compTeknologi = ""
        let compNoIzin = ""
        let compInstansi = ""
        let compTglIzin = ""

        
        // Tambahkan baris data
        const rows = data.items.map((item, index) =>
          [
          index + 1,
          item.company_name,
          this.formatDate(item.timestamp),
          item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan",
          item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan",
          item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan",
          item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan",
          item.debit ? item.debit.toFixed(5) : "Tidak Diwajibkan",
          item.beban_cod ? item.beban_cod.toFixed(5) : "Tidak Diwajibkan",
          item.beban_tss ? item.beban_tss.toFixed(5) : "Tidak Diwajibkan",
          item.beban_nhn ? item.beban_nhn.toFixed(5) : "Tidak Diwajibkan",
        ]);

        // data.company.map((item,index)=>{
        //   compName = item.company_name
        //   compAddress = item.company_address
        //   compLongitude = item.bujur
        //   compLatitude = item.lintang
        //   compTlp = item.company_phone
        //   userEmail = item.company_email
        //   compType = item.company_type_name
        //   compSumberLimbah = item.sumber_air_limbah
        //   compTeknologi = item.jenis_teknologi_pengolahan_air_limbah
        //   compNoIzin = item.air_limbah_number
        //   compInstansi = item.pejabat_penerbit_izin
        //   compTglIzin = item.tanggal_penerbitan_izin
        // })

          compName = data.company.name
          compAddress = data.company.address
          compLongitude = "None"
          compLatitude = "None"
          compTlp = data.company.phone
          userEmail = data.company.email
          compType = data.company.company_type
          compSumberLimbah = data.sparing.sumber_air_limbah
          compTeknologi = data.sparing.jenis_teknologi_pengolahan_air_limbah
          compNoIzin = data.sparing_permit.air_limbah_number
          compInstansi = data.sparing_permit.pejabat_penerbit_izin
          compTglIzin = data.sparing_permit.tanggal_penerbitan_izin



        let bodyProfile = [
                ["NAMA INDUSTRI", compName],
                ["ALAMAT", compAddress],
                ["LATITUDE", compLongitude],
                ["LONGITUDE", compLatitude],
                ["NOMOR TELEPON", compTlp],
                ["EMAIL", userEmail],
                ["JENIS USAHA", compType],
                ["SUMBER LIMBAH", compSumberLimbah],
                ["TEKNOLOGI PENGOLAHAN LIMBAH", compTeknologi],
                ["NO. IZIN", compNoIzin],
                ["INSTANSI PENERBIT", compInstansi],
                ["TANGGAL TERBIT IZIN", compTglIzin]
        ];

        
        const logo = new Image();
        logo.src = '/img/brand/img-logo-color.png';

        // Tambahkan informasi profil di atas judul
        doc.addImage(logo, 'JPEG', 10, 10, 20, 20);
        doc.text("Kementerian Lingkungan Hidup", 35, 20, { lineHeight: 15 }); // Sesuaikan nilai lineHeight sesuai kebutuhan

        // Tambahkan judul
        doc.setFontSize(11);
        doc.text("Laporan Database", 130, 20);
        doc.setTextColor(90, 90, 90)
        doc.setFont("bold");
        doc.text(`Data ini dibuat pada ${moment().format('DD-MM-YYYY HH:mm')}`, 130, 26);
        doc.setFont("normal");

      
        doc.autoTable({
          theme: "plain",
          body: bodyProfile,
          startY: 45, // Mulai di bawah judul profil
          columnStyles: {
            0: {
              fontStyle: "bold",
              halign: "left",
              cellWidth: 70,
              fillColor: [246, 249, 252],
            },
            1: { cellWidth: 100 },
          },
          styles: {
            cellPadding: 2,
            textColor: [41, 102, 75],
            // font: "times",
            lineWidth: 0.1,
            lineColor: [240, 240, 240],
            halign: "left",
          },
        });

        // Tambahkan tabel data di bawah informasi profil
        doc.autoTable({
          head: [header],
          theme: "striped",
          body: rows,
          headStyles: {
            fillColor: [41, 102, 75],
          },
          styles: {
            lineWidth: 0.2,
            halign: "center",
          },
        });

        doc.addPage();

          doc.autoTable({
                  head: [headerPemenuhan],
                  theme: "striped",
                  body: [['1','pH',3,100],['2','COD',3,100],['3','TSS',3,90],['4','NH3N',0,'Tidak Diwajibkan'],['5','Debit',3,100]],
                  headStyles: {
                    fillColor: [41, 102, 75]
                  },
                  styles: {
                    lineWidth: 0.2,
                    halign: "center"
                  },
          });

        // Simpan dokumen PDF
        doc.save("exportData.pdf");

        Swal.fire("Data di Unduh", "", "success");
        this.showDownloadModal = false;
      } catch (error) {
        console.error("Error:", error);
        // Tambahkan penanganan kesalahan jika diperlukan
      }
    },
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      Swal.fire("Data di Unduh", "", "success");
      this.showDownloadModalCSV = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
    },
    htmlTableToExcel(type) {
        var data = document.getElementById('tblToExcl');
        var excelFile = XLSX.utils.table_to_book(data, { sheet: "sheet1" });
        XLSX.write(excelFile, { bookType: type, bookSST: true, type: 'base64' });
        XLSX.writeFile(excelFile, 'ExportedFile:HTMLTableToExcel.' + type);
    },
    
    reportTableState() {
      return [];
    },
     // End of Filter
     
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    changeSizePage() {
      this.getData();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
},
};
</script>

<script setup>


// testing
let exportThisWithParameter = (() => {
    var base64;
    var format;
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"  ><meta http-equiv=Content-Type content="text/html; charset=utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets> <x:ExcelWorksheet><x:Name>{worksheet}</x:Name> <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions> </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook> </xml><![endif]--></head><body> <table>{table}</table></body></html>' 
        base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        },
        format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
        }
    return function (tableID, excelName) {
        tableID = document.getElementById(tableID)
        var ctx = { worksheet: excelName || 'Worksheet', table: tableID.innerHTML }
        //              window.location.href = uri + base64(format(template, ctx))
        var link = document.createElement("a");
        link.download = excelName;
        //link.charset = 'utf-8';
        link.href = uri + base64(format(template, ctx));
        link.click();
    }
})()

</script>


<style>
.headblue {
    background-color: #DEEBF7;
}

.customTable tr
 {
    text-align: center;
    border: 1px solid #000;
    color: black;
}
.customTable th
 {
    text-align: center;
    border: 1px solid #000;
    color: black;
    font-weight: 900;
    padding: 30px;
}

.customTable caption {
    background-color: #ccc;
}

.customTable .addHead {
    display: none;
}




.customTable .table-wrapper {
    margin: 10px 70px 70px;
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.customTable .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.customTable .fl-table td,
.fl-table th {
    text-align: center;
    padding: 10px;
}

.customTable .fl-table td {
    border-right: 1px solid #000;
    font-size: 12px;
}

.customTable .fl-table thead th {
    color: black;
    /* background: #4FC3A1; */
}










/* Responsive */

/* @media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
} */

</style>
  
  
  