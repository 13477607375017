export default [
    {
        logger_id:"5f4e1ca9167c90338b8fe176",
        nama_industri:"PT Pertamina (Persero) RU IV Cilacap",
        jenis_industri:"Pengolahan Minyak Bumi",
        kabkot:"Kabupaten Cilacap",
        provinsi:"Jawa Tengah",
        alamat:"Jl MT Haryono No. 77, Kabupaten Cilacap",
        phone:"0282508001",
        waste_source:"Air Limbah Proses (HB 39,49,66,70 I, 70 II, Outlet WWT RFCC, Outlet air pendingin), Air drainase (Jalan A dan Jalan N), Air Limbah Domestik (Outlet IPAL Domestik)",
        wtp_tech:"Fisika, Kimia, dan Biologi"
    },
  ];
  