<template>
    <div class="content">
      <div>
      <div>
      <b-col
          cols="12"
          class="mb-3 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
          <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
          >
          <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
          </el-select>
      </b-col>
      
  
      <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
      >
          <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
          >
          </el-table-column>
      </el-table>
      </div>
      <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
      <div class="">
          <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
  
          <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
          </span>
          </p>
      </div>
      <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
      >
      </base-pagination>
      </div>
      </div>
    </div>
  </template>
  <script>
  import { Table, TableColumn, Select, Option } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../PaginatedTables/clientPaginationMixin";
  import swal from "sweetalert2";
  import users from "./dummyDatabase"
  
  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        tableColumns: [
          {
            prop: "logger_id",
            label: "Logger ID",
            minWidth: 170,
          },
          {
            prop: "nama_industri",
            label: "Nama Industri",
            minWidth: 200,
            sortable: true,
          },
          {
            prop: "jenis_industri",
            label: "Jenis Industri",
            minWidth: 200,
            sortable: true,
          },
          {
            prop: "kabkot",
            label: "Kabkot",
            minWidth: 160,
          },
          {
            prop: "provinsi",
            label: "Provinsi",
            minWidth: 160,
          },
          {
            prop: "alamat",
            label: "Alamat",
            minWidth: 150,
          },
          {
            prop: "phone",
            label: "Phone",
            minWidth: 150,
          },
          {
            prop: "waste_source",
            label: "Waste Source",
            minWidth: 150,
          },
          {
            prop: "wtp_tech",
            label: "WTP Tech",
            minWidth: 150,
          }
        ],
        tableData: users,
        selectedRows: [],
      };
    },
    methods: {
      paginationChanged(page) {
        this.pagination.currentPage = page;
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.name}`,
          buttonsStyling: false,
          type: "success",
          confirmButtonClass: "btn btn-success btn-fill",
        });
      },
      handleEdit(index, row) {
        swal.fire({
          title: `You want to edit ${row.name}`,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info btn-fill",
        });
      },
      handleDelete(index, row) {
        swal
          .fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              this.deleteRow(row);
              swal.fire({
                title: "Deleted!",
                text: `You deleted ${row.name}`,
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            }
          });
      },
      deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      },
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows;
      },
      view(get) {
          this.$router.push("/adminIndustriView");
          console.log("view");
      }
    },
  };
  </script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }
  </style>
  